<template>
    <form-modal
        id="dataModal"
        ref="modal"
        :title="i18nTitle"
        :in-editing="true"
        :handle-hide="cleanModal"
        :handle-open="resetValidation"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <confirmation-form-modal
                ref="confirmationFormModal"
                :title="i18nTitle"
                :operation="'export'"
            />
            <b-form>
                <b-row>
                    <b-col>
                        <b-form-group
                            :label="i18nBusinessUnit"
                            class="required label-pdv"
                            label-for="modal-business-unit"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')"
                        >
                            <multiSelectWithService
                                :id="'modal-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="posExportModal.business_unit"
                                :input-function="$v.posExportModal.business_unit.id.$touch"
                                :select-class="validationClass($v.posExportModal.business_unit.id)"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="false"
                                :disabled="loading"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :title="getI18n('COMMON.export')"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import ExportJobService from '@src/services/ExportJobService'
import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import validation from '@src/mixins/validation'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'EXPORT_JOBS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        CancelButton,
        SaveButton,
        multiSelectWithService,
    },
    mixins: [
        validation,
        vuetableFormatters,
        swalFeedback
    ],
    props: {
        createParameters: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            posExportModal: {
                business_unit: null,
            },
            loading: false,
        }
    },
    validations: {
        posExportModal: {
            business_unit: {
                id: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
            },
        },
    },
    computed: {
        i18nTitle() {
            return this.getI18n(i18nKey, 'TYPES.point_of_sale_revenue_has_business_units')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nPreview() {
            return this.getI18n(i18nCommon, 'preview')
        },
        i18nTenLines() {
            return this.getI18n(i18nKey, 'TITLES.ten_lines')
        },
        i18nAllowedFormats() {
            return this.getI18n(i18nKey, 'TITLES.allowed_formats')
        },
        i18nSpreadsheetTemplate() {
            return this.getI18n(i18nCommon, 'spreadsheet_template')
        },
        i18nDownloadFile() {
            return `${this.getI18n(i18nCommon, 'download')} ${this.getI18n(i18nCommon, 'file')}`
        },
    },
    methods: {
        showModal() {
            this.cleanModal()
            this.$refs.modal.show()
        },

        hideModal() {
            this.$refs.modal.hide()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            Object.assign(this.posExportModal, {
                business_unit: null,
            })
            this.resetValidation()
        },

        handleCancel() {
            this.handleClose(true)
        },

        handleClose(close) {
            if (!close) {
                return
            }

            this.cleanModal()
            this.resetValidation()
            this.hideModal()
        },

        async handleOk() {
            this.$v.posExportModal.$touch()
            if (!this.$v.posExportModal.$invalid && !this.loading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.loading = true
            let parameters = await this.createParameters()

            parameters = {
                ...parameters,
                business_unit_id: [this.posExportModal.business_unit.id],
                export_type: 'point_of_sale_revenue_has_business_units',
                language: 'pt_BR',
            }
            const createResponse = await ExportJobService.create(
                parameters
            )
                .then((response) => response)
                .catch((error) => {
                    return error
                })

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$router.push({ name: 'exportJobs', params: { newJobCreated: true } })
            } else {
                this.negativeFeedback(this.getI18n('RESPONSES', 'create_not_possible'), createResponse.data.message)
            }

            this.loading = false
        },
    },
}
</script>
