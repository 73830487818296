import BaseService from '@src/services/BaseService'

class PosRevenueHasBusService extends BaseService {
    constructor() {
        super('pos-revenue-has-bus')
    }

    getByPos(id) {
        return this.axios.post(`${this.getUrl()}/get-by-pos/${id}`,)
    }
}

export default new PosRevenueHasBusService()
