<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import storeProfileTypes from '@src/mixins/intelligence/costOfServing/storeProfileTypes'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter, storeProfileTypes],
    data() {
        return {
            posRevenueChildrenFields: [
                {
                    key: 'business_unit.name',
                    label: this.getI18n(i18nCommon, 'business_unit'),
                    sortable: true,
                },
                {
                    key: 'revenue',
                    label: this.getI18n(i18nKey, 'FIELDS.revenue'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'ranking',
                    label: this.getI18n(i18nKey, 'IMPORT_FIELDS.ranking'),
                    sortable: true,
                },
                {
                    key: 'profile',
                    label: this.getI18n(i18nKey, 'IMPORT_FIELDS.profile'),
                    sortable: true,
                    formatter: this.storeProfileTypeToText,
                },
            ],
        }
    },
}
</script>
