<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-blue mr-1"
        href="javascript: void(0);"
        @click="$emit('onClick')">
        <i class="fe-download mr-1"/>
        {{ getI18n('COMMON', 'export') }}
    </button>
</template>

<script>
export default {}
</script>
