
<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        size="xl">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="name-input"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model.trim="posModal.name"
                                        class="input-pdv-blue"
                                        name="name"
                                        type="text"
                                        disabled
                                        autocomplete="off"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    v-mask="'##.###.###/####-##'"
                                    :label="i18nRegisteredNumber"
                                    label-for="registered-input"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="registeredNumber-input"
                                        v-model="posModal.registeredNumber"
                                        class="input-pdv-blue"
                                        name="registeredNumber"
                                        disabled
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nExternalCode"
                                    label-for="externalCode-input"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="externalCode-input"
                                        v-model.trim="posModal.externalCode"
                                        class="input-pdv-blue"
                                        name="externalCode"
                                        disabled
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nChain"
                                    label-for="modal-chain"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-chain'"
                                        ref="chainMultiselect"
                                        v-model="posModal.chain"
                                        :service="'pos-chains'"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nFlag"
                                    label-for="modal-flag"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-flag'"
                                        ref="flagMultiselect"
                                        v-model="posModal.flag"
                                        :service="'pos-flags'"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nChannel"
                                    label-for="modal-channel"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-channel'"
                                        ref="channelMultiselect"
                                        v-model="posModal.channel"
                                        :service="'pos-channels'"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nRegional"
                                    label-for="modal-regional"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-regional'"
                                        ref="regionalMultiselect"
                                        v-model="posModal.regional"
                                        :service="'pos-regionals'"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nCategory"
                                    label-for="modal-category"
                                    class="label-pdv"
                                >
                                    <multiSelectWithService
                                        :id="'modal-category'"
                                        ref="categoryMultiselect"
                                        v-model="posModal.category"
                                        :service="'pos-categories'"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nNumberCheckout"
                                    label-for="modal-number-checkout"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="modal-number-checkout"
                                        v-model.number="posModal.number_checkout"
                                        name="number_checkout"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        class="input-pdv-blue"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nAddress">
                        <b-row>
                            <b-col md=12>
                                <google-auto-complete
                                    id="autocomplete_address"
                                    ref="gautocomplete"
                                    :modal="gModal"
                                    :required="true"
                                    disabled
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nResponsible">
                        <topic-header
                            :caption="i18nCommecialResponsible"
                            icon="fe-shopping-cart"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nName"
                                    label-for="modal-commecial-responsible-name"
                                >
                                    <b-form-input
                                        id="modal-commecial-responsible-name"
                                        v-model.trim="posModal.commecial_responsible_name"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        disabled
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nEmail"
                                    label-for="modal-email"
                                >
                                    <b-form-input
                                        id="modal-email"
                                        v-model.trim="posModal.commecial_responsible_email"
                                        class="input-pdv-blue"
                                        name="email"
                                        aria-describedby="input-1-live-feedback"
                                        type="email"
                                        autocomplete="off"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <topic-header
                            :caption="i18nMerchandisingResponsible"
                            icon="fe-layout"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nName"
                                    label-for="modal-merchandising-responsible-name"
                                >
                                    <b-form-input
                                        id="modal-merchandising-responsible-name"
                                        v-model.trim="posModal.merchandising_responsib_name"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        disabled
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nEmail"
                                    label-for="modal-email"
                                >
                                    <b-form-input
                                        id="modal-email"
                                        v-model.trim="posModal.merchandising_responsib_email"
                                        class="input-pdv-blue"
                                        name="email"
                                        aria-describedby="input-1-live-feedback"
                                        type="email"
                                        autocomplete="off"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nRevenues">
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    label-for="modal-business-unit"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')">
                                    <multiSelectWithService
                                        :id="'modal-business-unit'"
                                        ref="businessUnitMultiselect"
                                        v-model="revenueModal.business_unit"
                                        :input-function="$v.revenueModal.business_unit.id.$touch"
                                        :select-class="validationClass($v.revenueModal.business_unit.id)"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="false"
                                        :preselect-first="true"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nRevenue"
                                    label-for="modal-revenue"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_revenue')"
                                >
                                    <money
                                        id="modal-revenue"
                                        v-model.trim="revenueModal.revenue"
                                        v-bind="money"
                                        name="revenue"
                                        class="form-control input-pdv-blue"
                                        :class="validationClass($v.revenueModal.revenue)"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="submitLoading"
                                        :state="validateField('revenue', 'revenueModal')"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nRanking"
                                    label-for="modal-ranking"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_ranking')"
                                >
                                    <b-form-input
                                        id="modal-ranking"
                                        v-model.number="revenueModal.ranking"
                                        class="input-pdv-blue"
                                        name="ranking"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        :disabled="submitLoading"
                                        min="0"
                                        :state="validateField('ranking', 'revenueModal')"
                                        @blur="$v.revenueModal.ranking.$touch;"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nProfile"
                                    label-for="modal-profile"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_profile')"
                                >
                                    <translated-multiselect
                                        id="modal-profile"
                                        v-model="profileSelected"
                                        :options="storeProfileTypes"
                                        :select-class="validationClass($v.revenueModal.profile)"
                                        :disabled="submitLoading"
                                        @input="changeProfile"

                                    />
                                </b-form-group>
                                <add-button
                                    ref="addButton"
                                    :busy="submitLoading"
                                    :disabled="deleteLoading"
                                    @onClick="handleOk"
                                />
                            </b-col>
                            <b-col class="col-sm-12 col-md-8">
                                <data-mode-vuetable
                                    ref="revenueRelationVuetable"
                                    v-model="posModal.point_of_sale_revenue_has_business_units"
                                    :fields="posRevenueChildrenActionFields"
                                    :busy="revenueLoading"
                                    :busy-delete="deleteLoading"
                                    :display-delete-button="!submitLoading"
                                    :disabled="submitLoading || deleteLoading"
                                    @onClickDelete="handleRemove"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
    </form-modal>
</template>

<script>
import { Money } from 'v-money'
import { required, integer, decimal, minValue } from 'vuelidate/lib/validators'
import PosRevenueHasBusService from '@src/services/PosRevenueHasBusService'
import GoogleAutoComplete from '@components/googleAutoComplete.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import FormModal from '@/src/components/FormModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import TopicHeader from '@src/components/TopicHeader.vue'
import AddButton from '@/src/components/AddButton'
import translatedMultiselect from '@components/translatedMultiselect'
import storeProfileTypes from '@src/mixins/intelligence/costOfServing/storeProfileTypes'
import posRevenueChildrenActionFields from '@/src/fields/childrens/posRevenueChildrenActionFields.vue'
import { i18n } from '@src/i18n'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Money,
        GoogleAutoComplete,
        multiSelectWithService,
        FormModal,
        DataModeVuetable,
        TopicHeader,
        AddButton,
        translatedMultiselect
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
        fieldsValidation,
        storeProfileTypes,
        posRevenueChildrenActionFields
    ],
    props: {
        modal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            gModal: {},
            posModal: this.modal,
            revenueModal: {},
            tabIndex: 0,
            submitLoading: false,
            deleteLoading: false,
            revenueLoading: false,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
            businessUnitFields: [
                {
                    key: 'name',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit'),
                    sortable: true,
                },
                {
                    key: 'contract_value',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_value'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'contract_time',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_time'),
                    sortable: true,
                },
            ],
            profileSelected: null,
        }
    },
    validations: {
        revenueModal: {
            business_unit: {
                id: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
            },
            revenue: {
                decimal,
                minValue: minValue(0)
            },
            ranking: {
                integer,
                minValue: minValue(1)
            },
            profile: {
                required,
            },
        },
    },
    computed: {
        i18nTitleModal() {
            return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nPos}`
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nFlag() {
            return this.getI18n(i18nKey, 'TITLES.flag')
        },
        i18nRegional() {
            return this.getI18n(i18nKey, 'TITLES.regional')
        },
        i18nChannel() {
            return this.getI18n(i18nKey, 'TITLES.channel')
        },
        i18nChain() {
            return this.getI18n(i18nKey, 'TITLES.chain')
        },
        i18nCategory() {
            return this.getI18n(i18nKey, 'TITLES.category')
        },
        i18nChainInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nRegisteredNumber() {
            return this.getI18n(i18nKey, 'FIELDS.registered_number')
        },
        i18nExternalCode() {
            return this.getI18n(i18nKey, 'FIELDS.external_code')
        },
        i18nRevenue() {
            return this.getI18n(i18nKey, 'FIELDS.revenue')
        },
        i18nNumberCheckout() {
            return this.getI18n(i18nKey, 'FIELDS.number_checkout')
        },
        i18nCommecialResponsible() {
            return this.getI18n(i18nKey, 'FIELDS.commecial_responsible')
        },
        i18nMerchandisingResponsible() {
            return this.getI18n(i18nKey, 'FIELDS.merchandising_responsible')
        },
        i18nRanking() {
            return this.getI18n('POS', 'IMPORT_FIELDS.ranking')
        },
        i18nProfile() {
            return this.getI18n('POS', 'IMPORT_FIELDS.profile')
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.pos',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nResponsible() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.responsible',
                modifier: 2,
            })
        },
        i18nAddress() {
            return this.getI18n(i18nCommon, 'address')
        },
        i18nBusinessUnit() {
            return this.getI18nModified({
                prefix: 'COMMON.business_unit',
                modifier: 2,
            })
        },
        i18nRevenues() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.revenue',
                modifier: 2,
            })
        },
    },
    methods: {
        changeProfile(option) {
            this.revenueModal.profile = option ? option.value : null
            this.$v.revenueModal.profile.$touch()
        },
        showModal() {
            this.businessUnitName = ''
            this.tabIndex = 0
            this.submitLoading = false
            this.deleteLoading = false
            this.revenueLoading = false
            this.replaceModalData(this.posModal)
            this.resetValidation()
            this.$refs.formModal.show()
        },
        replaceModalData(data) {
            Object.assign(this.gModal, data)
        },
        cleanModal() {
            this.posModal = Object.assign(this.posModal, {
                id: null,
                name: '',
                externalCode: '',
                registeredNumber: '',
                chain: null,
                channel: null,
                flag: null,
                regional_id: null,
                regional: null,
                category: null,
                address: null,
                city: {
                    id: null,
                    name: '',
                },
                state: {
                    id: null,
                    letter: '',
                    name: '',
                    slug: '',
                },
                latitude: null,
                longName: null,
                longitude: null,
                lat: null,
                long: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                zip_code: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
                number_checkout: 0,
                business_units: [],
                point_of_sale_revenue_has_business_units: [],
                commecial_responsible_name: null,
                commecial_responsible_email: null,
                merchandising_responsib_name: null,
                merchandising_responsib_email: null,
            })

            this.$v.posModal.$reset()

            if (this.$refs.gautocomplete) {
                this.$refs.gautocomplete.cleanModal()
            }
        },
        cleanRevenueModal() {
            this.revenueModal = Object.assign(this.revenueModal, {
                id: null,
                revenue: 0,
                profile: null,
                ranking: null,
            })
            this.profileSelected = null
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.revenueModal.$touch()
            if (!this.$v.revenueModal.$invalid && !this.submitLoading) {
                this.handleSubmit()
            }
        },

        async handleSubmit() {
            this.submitLoading = true

            /* eslint-disable camelcase */
            const posRevenueInfo = {
              point_of_sale_id: this.posModal.id,
              business_unit_id: this.revenueModal.business_unit.id,
              revenue: this.revenueModal.revenue ?? 0,
              ranking: this.revenueModal.ranking ?? null,
              profile: this.revenueModal.profile,
            }

            const response = await PosRevenueHasBusService.create(posRevenueInfo)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.reloadRevenues()
                this.$emit('refresh')
                this.resetValidation()
                this.cleanRevenueModal()
                this.positiveFeedback(this.i18nRevenue, 'created', 0)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nRevenue, response.data.errors)
            }

            this.submitLoading = false
        },
        async handleRemove(id) {
            const index = this.posModal.point_of_sale_revenue_has_business_units.findIndex(find => find.id === id);

            if (index > -1) {
                this.deleteLoading = true
                const response = await PosRevenueHasBusService.delete(id)
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.$emit('refresh')
                    this.posModal.point_of_sale_revenue_has_business_units.splice(index, 1);
                    this.positiveFeedback(this.i18nRevenue, 'deleted', 3)
                } else if (statusCode === '422') {
                    this.modalResponseErrors = response.data.errors
                    this.warningFeedbackApi(this.i18nRevenue, response.data.errors)
                }

                this.deleteLoading = false
            }
        },
        reloadRevenues() {
            this.revenueLoading = true
            PosRevenueHasBusService.getByPos(this.posModal.id)
                .then((response) => {
                    this.posModal.point_of_sale_revenue_has_business_units = response.data.data
                    this.revenueLoading = false
                })
                .catch((error) => {
                    this.warningFeedbackApi(this.i18nRevenue, error.data.errors)
                    this.revenueLoading = false
                })
        }
    },
}
</script>

<style>
</style>
